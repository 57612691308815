import PropTypes from 'prop-types';

// Props shared by all sections
const SectionShared = {
  types: {
    topOuterDivider: PropTypes.bool,
    bottomOuterDivider: PropTypes.bool,
    topDivider: PropTypes.bool,
    bottomDivider: PropTypes.bool,
    hasBgColor: PropTypes.bool,
    invertColor: PropTypes.bool
  },
  defaults: {
    topOuterDivider: false,
    bottomOuterDivider: false,
    topDivider: false,
    bottomDivider: false,
    hasBgColor: false,
    invertColor: false
  }
}

// Default section props
export const SectionProps = {
  types: {
    ...SectionShared.types
  },
  defaults: {
    ...SectionShared.defaults
  }
}

var itemTextShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
})

var placeHolderText = {
  "title": "",
  "text": "",
  "items": [
    {
      "title": "",
      "text": "",
      "icon": ""
    }
  ],
  "demo": {
    "title": "",
    "text": "",
    "text2": ""
  }
}

// Section split props
export const SectionSplitProps = {
  types: {
    ...SectionShared.types,
    invertMobile: PropTypes.bool,
    invertDesktop: PropTypes.bool,
    alignTop: PropTypes.bool,
    imageFill: PropTypes.bool,
    text: PropTypes.exact({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(itemTextShape.isRequired).isRequired,
      demo: PropTypes.exact({
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        text2: PropTypes.string.isRequired
      }).isRequired,
    }).isRequired
  },
  defaults: {
    ...SectionShared.defaults,
    invertMobile: false,
    invertDesktop: false,
    alignTop: false,
    imageFill: false,
    text: placeHolderText
  }
}

// Section tiles props
export const SectionTilesProps = {
  types: {
    ...SectionShared.types,
    pushLeft: PropTypes.bool,
    text: PropTypes.exact({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(itemTextShape.isRequired).isRequired,
      demo: PropTypes.exact({
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        text2: PropTypes.string.isRequired
      }).isRequired,
    }).isRequired
  },
  defaults: {
    ...SectionShared.defaults,
    pushLeft: false,
    text: placeHolderText
  }
}