import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import SectionHeader from '../components/sections/partials/SectionHeader';

import TextStudios from '../assets/text/studios.json';
import TextUsers from '../assets/text/users.json';

const sectionHeader = {
  title: "Lancement fin 2021",
  paragraph: "L'application EasyRec sera disponible sur l'App Store et le Google Play Store"
};

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles text={TextStudios} />
      <FeaturesSplit text={TextStudios} invertMobile topDivider imageFill className="illustration-section-02" />
      <FeaturesTiles text={TextUsers} />
      <FeaturesSplit text={TextUsers} invertMobile topDivider imageFill className="illustration-section-02" />
      <SectionHeader data={sectionHeader} className="center-content" />
      {/* <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </>
  );
}

export default Home;